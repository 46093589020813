<template>
  <div class="app-download">
    <img object-fit="cover" src="../assets/images/app/download-bg.png"/>
    <div class="qrcode">
      <img src="../assets/images/app/download-qrcode.png"/>
    </div>
    <div id="download-button" @click="doDownload">立即下载</div>
  </div>
</template>

<script>
export default {
  name: "app-download",
  methods: {
    doDownload() {
      const userAgent = window.navigator.userAgent;
      if (/MicroMessenger/.test(userAgent)) {
        // 微信浏览器
        this.$message.warning("请使用其他浏览器进行下载!");
        return;
      }
      // window.open("/jitingche.apk");
      window.open(
          "https://jingli-parking.oss-cn-qingdao.aliyuncs.com/app/userapp/jitingche.apk"
      );
    },
  },
  mounted() {
    document.title = "比特泊车-用户端App下载";
    const userAgent = window.navigator.userAgent;
    if (/MicroMessenger/.test(userAgent)) {
      // 微信浏览器
      this.$message.warning("请使用其他浏览器进行下载!");
    }
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/convert.less");

.app-download {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  position: relative;

  img {
    max-width: 100%;
    height: auto;
  }

  .qrcode {
    position: absolute;
    .pxToVW(170px, width);
    .pxToVW(170px, height);
    background: red;
    .pxToVW(101px, left);
    .pxToVW(452px, top);
  }

  #download-button {
    position: fixed;
    .pxToVH(60px, height);
    border: 1px solid #fff;
    background: transparent;
    border-radius: 100px;
    .pxToVW(20px, left);
    .pxToVW(20px, right);
    .pxToVW(20px, bottom);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .pxToVW(20px, font-size);
    color: #fff;
  }
}
</style>
