<template>
  <div class="coupon_qrcode">
    <div class="main-content">
      <div class="plateNumber_color_area">
        <div :class="format(currentPlateNumberType)" class="plateNumber_color">
          <span class="inner_text">{{ currentPlateNumberType.text }}</span>
        </div>
        <div class="other_plateNumber">
          <div @click="dropdownShow">
            其他<img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAOCAYAAAAxDQxDAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADESURBVHgBrdDRDcIgEAbg/yp9dwRGcIVuUDdgA93AFXQDN7AbdAVH6Ai+S0SgMWkqlKPlHgjkv7svgWCrbZWEwAkat667DyhYbndV41L5l4Ai4Ew1eo8WRGiH3hioEdK42vMJA1kK+yEgSLebJsGehA2Agw0H80az9hvniNFoaNawGQshdseLAo2rsRjiMooMZGNLSBTKxVLIIsTFOEgSSmFchAXFMD/MRNhQCMPHT0sOkgX9YWOxkGxogj3c3SJHDuLqC0VjqPb+QR0pAAAAAElFTkSuQmCC"
              style="max-width: 14px; height: auto; margin-left: 4px"
          />
          </div>
          <div v-if="showDropdown" class="plateNumber_type_dropdown">
            <div
                v-for="(item, index) in plateNumberTypeList"
                :key="index"
                @click="changePlateNumber(item)"
            >
              <div :class="format(item)" class="out-wrap">
                <div class="inner-wrap">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="plateNumber">
        <div
            v-for="(item, index) in plateNumber"
            :key="index"
            class="plateNumber-item"
            @click="showKeyboard(index)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="common_button" @click="getCoupon">获取优惠券</div>
    <PlateNumber
        ref="plateNumber"
        :keyType="keyboardType"
        @inputword="onInputWord"
    />
  </div>
</template>

<script>
import PlateNumber from "@/components/NumplateKeyboard";
import qs from "qs";
import {showLoading} from "@/easy/popup";
import {publishCouponByQRCode} from "@/api/lease";

// 车辆类型 01 黄底黑字 02 蓝底白字 03绿色 04黄绿 06 黑底白字 23 白底黑字 99 其他
export default {
  name: "coupon_qrcode",
  data() {
    return {
      plateNumber: ["鲁", "", "", "", "", "", ""],
      keyboardShow: false,
      keyboardType: "province",
      currentPlateNumberType: {
        type: "02",
        text: "蓝牌",
      },
      plateNumberTypeList: [
        {
          type: "02",
          text: "蓝牌",
        },
        {
          type: "03",
          text: "新能源",
        },
        {
          type: "01",
          text: "黄牌",
        },
        {
          type: "23",
          text: "白牌",
        },
        {
          type: "06",
          text: "黑牌",
        },
      ],
      index: 0,
      showDropdown: false,
      params: {
        id: "",
        vehicleColor: "",
        vehicleNumber: "",
      },
    };
  },
  components: {
    PlateNumber,
  },
  methods: {
    format(item) {
      return "color_" + item.type;
    },
    dropdownShow() {
      this.showDropdown = !this.showDropdown;
    },
    changePlateNumber(item) {
      this.currentPlateNumberType = item;
      this.showDropdown = false;

      if (item.type === "03" && this.plateNumber.length === 7) {
        this.plateNumber.push("");
      } else if (this.plateNumber.length === 8) {
        this.plateNumber.pop();
      }
    },
    showKeyboard() {
      this.keyboardType = "province";
      this.$refs.plateNumber.show(this.keyboardType);
    },
    onInputWord(e) {
      if (e.type === "input") {
        if (this.index === this.plateNumber.length) {
          return;
        }

        this.plateNumber[this.index] = e.value;
        this.index++;
      } else if (e.type === "del") {
        if (this.plateNumber.length === 0) {
          return;
        }
        this.plateNumber[this.index - 1] = "";
        this.index--;
      }
      this.$forceUpdate();
    },
    async getCoupon() {
      const plateNumberColor = this.currentPlateNumberType.type;
      const plateNumber = this.plateNumber.join("");

      if (plateNumber === "") {
        return;
      }

      const mask = showLoading("请稍等...");
      const res = await publishCouponByQRCode({
        numberPlate: plateNumber,
        plateColor: plateNumberColor,
        couponRuleId: this.params.id,
      });
      mask.hide();

      if (res && res.code === 30 && res.result) {
        this.$router.replace({
          path: "/coupon_qrcode_result",
        });
      }
    },
  },
  mounted() {
    document.title = "比特泊车-扫码获取优惠券";

    const {coupon_id} = qs.parse(window.location.href.split("?")[1]);
    this.params.id = coupon_id;
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/convert.less");

.coupon_qrcode {
  // TODO
  width: 100vw;
  height: 100vh;
  background: #e5e5e5;
  overflow: hidden;

  .main-content {
    background: #fff;
    border-radius: 6px;
    margin: 12px;
    .pxToVW(10px, padding);

    .plateNumber {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .pxToVH(30px, margin-top);

      .plateNumber-item {
        flex: 1;
        border: 1px solid #acafbc;
        border-radius: 4px;
        .pxToVH(56px, height);
        .pxToVW(2px, margin);
        .pxToVW(20px, font-size);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "黑体";
      }
    }

    .plateNumber_color_area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .pxToVW(14px, font-size);
      color: #333;

      .plateNumber_color {
        position: relative;

        .inner_text {
          .pxToVW(4px, padding);
        }

        &::after {
          content: "";
          position: absolute;
          .pxToVH(6px, height);
          width: 100%;
          left: 0;
          .pxToVH(-6px, bottom);
          border-radius: 2px;
        }

        // 黄色
        &.color_01 {
          &::after {
            content: "";
            position: absolute;
            .pxToVH(6px, height);
            width: 100%;
            background: #f5ab41;
            left: 0;
            .pxToVH(-6px, bottom);
          }
        }

        // 蓝色
        &.color_02 {
          &::after {
            content: "";
            position: absolute;
            .pxToVH(6px, height);
            width: 100%;
            background: #3a69f5;
            left: 0;
            .pxToVH(-6px, bottom);
          }
        }

        // 绿色
        &.color_03 {
          &::after {
            content: "";
            position: absolute;
            .pxToVH(6px, height);
            width: 100%;
            background: linear-gradient(180deg, #cfe4d2 0%, #44c759 100%);
            left: 0;
            .pxToVH(-6px, bottom);
          }
        }

        // 黑色
        &.color_06 {
          &::after {
            content: "";
            position: absolute;
            .pxToVH(6px, height);
            width: 100%;
            background: #000;
            left: 0;
            .pxToVH(-6px, bottom);
          }
        }

        // 白色
        &.color_23 {
          &::after {
            content: "";
            position: absolute;
            .pxToVH(6px, height);
            width: 100%;
            background: #fff;
            box-sizing: border-box;
            border: 1px solid #000;
            left: 0;
            .pxToVH(-6px, bottom);
          }
        }
      }

      .other_plateNumber {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .plateNumber_type_dropdown {
          position: absolute;
          width: 72px;
          height: 176px;
          left: -26px;
          top: 20px;

          background: #ffffff;
          border: 1px solid #f2f2f2;
          border-radius: 6px;
          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);

          // display: flex;
          // flex-direction: column;
          // justify-content: center;

          .out-wrap {
            width: 56px;
            height: 26px;

            background: red;
            margin: 0 auto;

            box-sizing: border-box;
            margin-top: 8px;

            border-radius: 2px;
            padding: 1px;

            .inner-wrap {
              box-sizing: border-box;
              line-height: 22px;
              text-align: center;

              border: 1px solid #fff;
            }

            &.color_01 {
              background: #f5ab41;
              color: #fff;
            }

            &.color_02 {
              background: #3a69f5;
              color: #fff;
            }

            &.color_03 {
              background: linear-gradient(180deg, #cfe4d2 0%, #44c759 100%);
              color: #fff;
            }

            &.color_23 {
              height: 27px;
              background: #fff;
              color: #000;
              border: 1px solid #000;
              box-sizing: border-box;

              .inner-wrap {
                border-color: #000;
                line-height: 21px;
              }
            }

            &.color_06 {
              background: #000;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .common_button {
    background: #3a69f5;
    border-radius: 6px;
    color: #fff;
    .pxToVW(16px, font-size);
    .pxToVW(30px, margin-right);
    .pxToVW(30px, margin-left);
    .pxToVW(10px, padding);
    text-align: center;
    .pxToVH(60px, margin-top);
  }
}
</style>
