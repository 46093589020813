<template>
  <div class="success-result">
    <div class="result-icon success"></div>
    <h3>恭喜你</h3>
    <div class="tips">你已成功领取优惠券</div>
  </div>
</template>

<script>
export default {
  name: "coupon_qrcode_result",
  data() {
    return {};
  },
  mounted() {
    // const query = this.$route.query;
    // this.type = query.type;
    // this.amount = query.amount;
  },
};
</script>

<style lang="less" scoped>
.success-result {
  height: 100vh;
  background: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .result-icon {
    width: 7.2rem;
    height: 7.2rem;

    margin-top: -10rem;

    border-radius: 100px;
    background: #3a69f5;

    position: relative;

    &.success {
      &::before {
        position: absolute;
        content: "";
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        width: 1.3rem;
        height: 2.3rem;
        left: 3rem;
        top: 2rem;
        transform: rotate(45deg);
      }
    }

    &.cancel {
      &::before {
        position: absolute;
        content: "×";
        font-size: 5rem;
        left: 2.1rem;
        top: 0.2rem;
        color: #fff;
      }
    }
  }

  h3 {
    margin-top: 1rem;
    font-size: 2.4rem;
    color: #223263;
  }

  .tips {
    color: #223263;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
}
</style>
