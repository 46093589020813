const routes = [
    {
        path: '/home',
        name: 'home',
        component: () => import('@/pages/Home'),
        meta: {
            requiresAuth: false,
            isAdmin: false,
            title: '首页',
            icon: "el-icon-bell",
            keepAlive: true,
            showTab: 1
        }
    },
    // 监控管理
    {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/pages/monitor'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '监控管理',
            icon: "el-icon-bell"
        },
        children: [
            /** 动态菜单 */
            {
                path: '/monitor/realtime',
                name: 'realtime',
                component: () => import('@/pages/monitor/realtime'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '实时监控',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    showTab: 1
                }
            },
            {
                path: '/monitor/road_inside_monitor',
                name: 'road_inside_monitor',
                component: () => import('@/pages/monitor/road_inside_monitor'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '路内-实时监控',
                    icon: "el-icon-bell",
                    hidden: true
                }
            },
            {
                path: '/monitor/road_outside_monitor',
                name: 'road_outside_monitor',
                component: () => import('@/pages/monitor/road_outside_monitor'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '路外-实时监控',
                    icon: "el-icon-bell",
                    hidden: true
                }
            },
            /** 动态菜单 */
            {
                path: '/monitor/parkingspace',
                name: 'parkingspace',
                component: () => import('@/pages/monitor/parkingspace'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '泊位监控',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/monitor/parkingspace/detail',
                name: 'parking-monitor-detail',
                component: () => import('@/pages/monitor/parkingspace_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '监控详情',
                    icon: "el-icon-bell",
                    hidden: true
                },
            },
        ]
    },
    // 用户管理
    {
        path: '/user_manage',
        name: 'user',
        component: () => import('@/pages/user'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '用户管理',
            icon: "el-icon-bell"
        },
        children: [
            /**动态菜单 */
            {
                path: '/user_manage/user_manage',
                name: 'user_manage',
                component: () => import('@/pages/user/user_manage/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '用户管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/user_manage/user_manage/bills',
                name: 'user_manage_bills',
                component: () => import('@/pages/user/user_manage/bills'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '用户卡流',
                    icon: "el-icon-bell",
                    hidden: true
                },
            },
            {
                path: '/user_manage/user_manage/detail',
                name: 'user_manage_detail',
                component: () => import('@/pages/user/user_manage/detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '用户详情',
                    icon: "el-icon-bell",
                    hidden: true
                },
            },
            /**动态菜单 */
            {
                path: '/user_manage/vehicle_manage',
                name: 'vehicle_manage',
                component: () => import('@/pages/user/vehicle_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '车辆管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/user_manage/vehicle_detail',
                name: 'vehicle_detail',
                component: () => import('@/pages/user/vehicle_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '车辆管理详情',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/user_manage/vehicle_manage"
                },
            },
            /**动态菜单 */
            {
                path: '/user_manage/merchant_manage',
                name: 'merchant_manage',
                component: () => import('@/pages/user/merchant_manage'),
                meta: {
                    requiresAuth: true,
                    title: "商户管理",
                    icon: 'el-icon-bell',
                    keepAlive: true
                },
            },
            {
                path: '/user_manage/add_merchant',
                name: 'add_merchant',
                component: () => import('@/pages/user/add_merchant'),
                meta: {
                    requiresAuth: true,
                    title: "添加商户",
                    icon: 'el-icon-bell',
                    hidden: true
                },
            },
            {
                path: '/user_manage/recharge_list',
                name: 'recharge_list',
                component: () => import('@/pages/user/recharge_list'),
                meta: {
                    requiresAuth: true,
                    title: "商户充值记录",
                    icon: 'el-icon-bell',
                    keepAlive: true
                },
            },
            {
                path: '/user_manage/merchant_detail',
                name: 'merchant_detail',
                component: () => import('@/pages/user/merchant_detail'),
                meta: {
                    requiresAuth: true,
                    title: "商户详情",
                    icon: 'el-icon-bell',
                    hidden: true,
                    activePath: "/user_manage/merchant_manage"
                },
            },
            {
                path: '/user_manage/merchant_verify',
                name: 'merchant_verify',
                component: () => import('@/pages/user/merchant_verify'),
                meta: {
                    requiresAuth: true,
                    title: "商户审核",
                    icon: 'el-icon-bell',
                    hidden: true
                },
            },
            /**动态菜单 */
            {
                path: '/user_manage/sentrybox_manage/person_manage',
                name: 'sentrybox_manage',
                component: () => import('@/pages/user/sentrybox_manage/person_manage'),
                meta: {
                    title: "岗亭管理",
                    icon: 'el-icon-bell',
                    keepAlive: true
                },
            },
            /**动态菜单 */
            {
                path: '/user_manage/settlement_manage',
                name: 'settlement_manage',
                component: () => import('@/pages/user/settlement_manage/index'),
                meta: {
                    requiresAuth: true,
                    title: "车场方管理",
                    icon: 'el-icon-bell',
                    keepAlive: false
                },
            },
            {
                path: '/user_manage/settlement_manage/add_settlement',
                name: 'add_settlement',
                component: () => import('@/pages/user/settlement_manage/add_settlement'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '添加车场方',
                    activePath: "/user_manage/settlement_manage"
                },
            },
            {
                path: '/user_manage/settlement_manage/detail_settlement',
                name: 'detail_settlement',
                component: () => import('@/pages/user/settlement_manage/detail_settlement'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '车场方详情',
                },
            },
            {
                path: '/user_manage/sentry_manage',
                name: 'sentry_manage',
                component: () => import('@/pages/user/sentry_manage/index'),
                meta: {
                    requiresAuth: true,
                    title: "岗亭端管理",
                    icon: 'el-icon-bell',
                    keepAlive: true
                },
            },
            {
                path: '/user_manage/settlement_manage/relation_parkinglot',
                name: 'relation_parkinglot',
                component: () => import('@/pages/user/settlement_manage/relation_parkinglot'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '关联车场',
                },
            },
            {
                path: '/user_manage/settlement_manage/authentication_manage',
                name: 'authentication_manage',
                component: () => import('@/pages/user/settlement_manage/authentication_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '认证管理',
                },
            },
        ],
    },
    // 路内管理
    {
        path: '/road_inside',
        name: 'road_inside',
        component: () => import('@/pages/road_inside'),
        children: [
            /**动态菜单 */
            {
                path: '/road_inside/bulletin_board',
                name: 'road_inside_bulletin_board',
                component: () => import('@/pages/road_inside/bulletin_board'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '路内看板',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    showTab: 1
                },
            },
            /**动态菜单 */
            {
                path: '/road_inside/parking_order',
                name: 'parking_order_inside',
                component: () => import('@/pages/road_inside/parking_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/road_inside/parking_order_history',
                name: 'parking_order_inside_history',
                component: () => import('@/pages/road_inside/parking_order_history'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '历史订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/road_inside/parking_order/parking_order_detail',
                name: 'parking_inside_order_detail',
                component: () => import('@/pages/road_inside/parking_order_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单详情',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/road_inside/parking_order"
                },
            },
            {
                path: '/road_inside/parking_order/parking_order_history_detail',
                name: 'parking_inside_order_history_detail',
                component: () => import('@/pages/road_inside/parking_order_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单详情',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/road_inside/parking_order_history"
                },
            },
            /**动态菜单 */
            {
                path: '/road_inside/patrol_inspection',
                name: 'patrol_inspection',
                component: () => import('@/pages/road_inside/patrol_inspection'),
                redirect: '/road_inside/patrol_inspection/person_manage',
                children: [
                    /**动态菜单 */
                    {
                        path: '/road_inside/patrol_inspection/person_manage',
                        name: 'people_manage',
                        component: () => import('@/pages/road_inside/patrol_inspection/person_manage'),
                        meta: {
                            title: '巡检人员',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    /**动态菜单 */
                    {
                        path: '/road_inside/patrol_inspection/receipt_manage',
                        name: 'receipt_manage',
                        component: () => import('@/pages/road_inside/patrol_inspection/receipt_manage'),
                        meta: {
                            title: '小票记录',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    /**动态菜单 */
                    {
                        path: '/road_inside/patrol_inspection/receipt_report',
                        name: 'receipt_report',
                        component: () => import('@/pages/road_inside/patrol_inspection/receipt_report'),
                        meta: {
                            title: '小票报表',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    /**动态菜单 */
                    {
                        path: '/road_inside/patrol_inspection/inspect_track',
                        name: 'inspect_track',
                        component: () => import('@/pages/road_inside/patrol_inspection/inspect_track'),
                        meta: {
                            title: '鹰眼轨迹',
                            icon: 'el-icon-bell',
                            keepAlive: true
                        }
                    },
                    /**动态菜单 */
                    {
                        path: '/road_inside/patrol_inspection/ticket_template',
                        name: 'inspector_ticket_template',
                        component: () => import('@/pages/road_inside/patrol_inspection/ticket_template/index'),
                        meta: {
                            title: '小票模板',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                ],
                meta: {
                    title: '巡检稽查',
                    icon: "el-icon-bell"
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '路内管理',
            icon: "el-icon-bell"
        }
    },
    // 路外管理
    {
        path: '/road_outside',
        name: 'road_outside',
        component: () => import('@/pages/road_outside'),
        children: [
            /**动态菜单 */
            {
                path: '/road_outside/bulletin_board',
                name: 'road_outside_bulletin_board',
                component: () => import('@/pages/road_outside/bulletin_board'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '路外看板',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    showTab: 1
                },
            },
            /**停车订单 */
            {
                path: '/road_outside/parking_order',
                name: 'parking_order_outside',
                component: () => import('@/pages/road_outside/parking_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/road_outside/parking_order_history',
                name: 'parking_order_outside_history',
                component: () => import('@/pages/road_outside/parking_order_history'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '历史订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/road_outside/parking_order/parking_order_detail',
                name: 'parking_outside_order_detail',
                component: () => import('@/pages/road_outside/parking_order_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单详情',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/road_outside/parking_order"
                },
            },
            {
                path: '/road_outside/parking_order/parking_order_history_detail',
                name: 'parking_outside_order_history_detail',
                component: () => import('@/pages/road_outside/parking_order_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '停车订单详情',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/road_outside/parking_order_history"
                },
            },
            {
                path: '/road_outside/entrance_monitoring',
                name: 'entrance_monitoring',
                component: () => import('@/pages/road_outside/entrance_monitoring'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '出/入口监控',
                    icon: "el-icon-bell",
                    hidden: true,
                    keepAlive: true
                },
            },
            {
                path: '/road_outside/lifting_record',
                name: 'lifting_record',
                component: () => import('@/pages/road_outside/lifting_record'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '抬/落杆记录',
                    icon: "el-icon-bell",
                    hidden: true,
                    keepAlive: true
                },
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '路外管理',
            icon: "el-icon-bell"
        }
    },
    // 停车场管理
    {
        path: '/parking_manage',
        name: 'parking_manage',
        // redirect: '/parking_manage/parkingList',
        component: () => import('@/pages/parking'),
        children: [
            /**动态菜单 */
            {
                path: '/parking_manage/parkingList',
                name: 'parkingList',
                component: () => import('@/pages/parking/list'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '车场管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/parking_manage/external_parking',
                name: 'external_parking',
                component: () => import('@/pages/parking/external_parking'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '外部车场接入',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/parking_manage/add',
                name: 'parking_add',
                component: () => import('@/pages/parking/add'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '车场信息维护',
                    activePath: "/parking_manage/parkingList"
                },
            },
            {
                path: '/parking_manage/add_external',
                name: 'add_external',
                component: () => import('@/pages/parking/add_external'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '外部车场信息维护',
                    activePath: "/parking_manage/external_parking"
                },
            },
            {
                path: '/parking_manage/voice',
                name: 'voice_manage',
                component: () => import('@/pages/parking/voice_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '语音播报设置',
                    activePath: "/parking_manage/parkingList"
                },
            },
            {
                path: '/parking_manage/price_detail',
                name: 'price_detail',
                component: () => import('@/pages/parking/price_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '查看计费规则',
                },
            },
            {
                path: '/parking_manage/edit_parkingspace',
                name: 'edit_parkingspace',
                component: () => import('@/pages/parking/edit_parkingspace'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '泊位管理',
                },
            },
            {
                path: '/parking_manage/edit_parkingGate',
                name: 'edit_parkingGate',
                component: () => import('@/pages/parking/edit_parkingGate'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '通道配置',
                    activePath: "/parking_manage/parkingList"
                },
            },
            {
                path: '/parking_manage/detail',
                name: 'parking_detail',
                component: () => import('@/pages/parking/detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '车场详情',
                    activePath: "/parking_manage/parkingList"
                },
            },
            {
                path: '/parking_manage/add_price',
                name: 'add_price',
                component: () => import('@/pages/parking/add_price'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '计费规则',
                },
            },
            /**动态菜单 */
            {
                path: '/resource/guide_screen/index',
                name: 'guide_screen',
                component: () => import('@/pages/guide_screen/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '诱导屏管理',
                    keepAlive: true
                },
            }
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '资源管理',
            icon: "el-icon-bell"
        }
    },
    // 充电管理
    {
        path: '/charge_manage',
        name: 'charge_manage',
        component: () => import('@/pages/charge_manage/index'),
        children: [
            /**动态菜单 */
            {
                path: '/charge_manage/bulletin_board',
                name: 'charge_bulletin_board',
                // bulletin_board
                component: () => import('@/pages/charge_manage/bulletin_board'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '充电看板',
                    keepAlive: true,
                    showTab:1
                },
            },
            {
                path: '/charge_manage/bicycle',
                name: 'bicycle',
                component: () => import('@/pages/charge_manage/bicycle'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '二轮车充电站',
                    keepAlive: true
                },
            },
            {
                path: '/charge_manage/energy',
                name: 'energy',
                component: () => import('@/pages/charge_manage/energy'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '新能源充电站',
                    keepAlive: true
                },
            },
            {
                path: '/charge_manage/bicycle_order',
                name: 'bicycle_order',
                component: () => import('@/pages/charge_manage/bicycle_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '二轮车充电订单',
                    keepAlive: true
                },
            },
            {
                path: '/charge_manage/energy_order',
                name: 'energy_order',
                component: () => import('@/pages/charge_manage/energy_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '新能源充电订单',
                    keepAlive: true
                },
            },
            {
                path: '/charge_manage/bicycle_setting',
                name: 'bicycle_setting',
                component: () => import('@/pages/charge_manage/bicycle_setting'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '二轮车配置',
                    keepAlive: true,
                    activePath: "/charge_manage/bicycle"
                },
            },
            {
                path: '/charge_manage/energy_setting',
                name: 'energy_setting',
                component: () => import('@/pages/charge_manage/energy_setting'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '新能源配置',
                    keepAlive: true,
                    activePath: "/charge_manage/energy"
                },
            },
            {
                path: '/charge_manage/bicycle_rules',
                name: 'bicycle_rules',
                component: () => import('@/pages/charge_manage/bicycle_rules'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '二轮车计费规则',
                    keepAlive: true,
                    activePath: "/charge_manage/bicycle"
                },
            },
            {
                path: '/charge_manage/energy_rules',
                name: 'energy_rules',
                component: () => import('@/pages/charge_manage/energy_rules'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    title: '新能源计费规则',
                    keepAlive: true,
                    activePath: "/charge_manage/energy"
                },
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '资源管理',
            icon: "el-icon-bell"
        }
    },
    // 设备管理
    {
        path: '/device_manage',
        name: 'device_manage',
        component: () => import('@/pages/device'),
        children: [
            /**动态菜单 */
            {
                path: '/device_manage/deviceList',
                name: 'deviceList',
                component: () => import('@/pages/device/list'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '设备管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
            {
                path: '/device_manage/add',
                name: 'device_add',
                component: () => import('@/pages/device/add'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    icon: "el-icon-bell",
                    hidden: true,
                    title: '设备绑定',
                    activePath: "/device_manage/deviceList"
                },
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '资源管理',
            icon: "el-icon-bell"
        }
    },
    // 长租管理
    {
        path: '/lease',
        name: 'lease',
        component: () => import('@/pages/lease'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '长租管理',
            icon: "el-icon-bell",
            // keepAlive: true
        },
        children: [
            /**动态菜单 */
            {
                path: 'lease_manage',
                name: 'lease_manage',
                component: () => import('@/pages/lease/lease_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '长租套餐',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**动态菜单 */
            {
                path: 'long_activity',
                name: 'lease_activity',
                component: () => import('@/pages/lease/lease_activity'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '长租活动',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**动态菜单 */
            {
                path: 'lease_order',
                name: 'long_order',
                component: () => import('@/pages/lease/lease_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '长租订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/business_manage/detail_long_order',
                name: 'detail_long_order',
                component: () => import('@/pages/lease/long_control/detail_long_order'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '编辑长租订单',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/lease/lease_order"
                },
                hidden: true,

            },
            {
                path: '/business_manage/add_long_way',
                name: 'edit_long_way',
                component: () => import('@/pages/lease/long_control/add_longWay'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '创建长租活动',
                    icon: "el-icon-bell",
                    activePath: "/lease/long_activity"
                },
                hidden: true,

            },
            {
                path: '/business_manage/detail_long_way',
                name: 'detail_long_way',
                component: () => import('@/pages/lease/long_control/detail_longWay'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '长租活动详情',
                    icon: "el-icon-bell",
                    activePath: "/lease/long_activity"
                },
                hidden: true,

            },
            {
                path: '/business_manage/edit_longPackage',
                name: 'edit_longPackage',
                component: () => import('@/pages/lease/long_control/edit_longPackage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '编辑长租套餐',
                    icon: "el-icon-bell",
                    activePath: "/lease/lease_manage"
                },
                hidden: true,

            },
        ]
    },
    // 设备管理
    {
        path: '/approval_manage',
        name: 'approval_manage',
        component: () => import('@/pages/approval_manage/index'),
        children: [
            /**动态菜单 */
            {
                path: '/approval_manage/index',
                name: 'approval_index',
                component: () => import('@/pages/approval_manage/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '联网审核',
                    icon: "el-icon-bell",
                    keepAlive: true
                },
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '联网审核',
            icon: "el-icon-bell",
            keepAlive: true
        }
    },
    {
        path: '/order_manage',
        name: 'order_manage',
        component: () => import('@/pages/order_manage'),
        children: [
            {
                path: '/order_manage/recovered_order',
                name: 'recovered_order',
                component: () => import('@/pages/order_manage/recovered_order'),
                meta: {
                    title: '追缴订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/order_manage/recovered_history',
                name: 'recovered_history',
                component: () => import('@/pages/order_manage/recovered_history'),
                meta: {
                    title: '历史追缴',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '订单审核',
            icon: "el-icon-bell"
        }
    },
    /**动态菜单 */
    {
        path: 'coupon_manage',
        name: 'coupon_manage',
        component: () => import('@/pages/coupon_manage'),
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '优惠券管理',
            icon: "el-icon-bell",
            keepAlive: true
        },
        children: [
            /**事件管理 */
            {
                path: 'event_manage',
                name: 'event_manage',
                component: () => import('@/pages/coupon_manage/event_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '事件管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/approval_manage/detail',
                name: 'approval_detail',
                component: () => import('@/pages/approval_manage/detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '联网审核详情',
                    icon: "el-icon-bell",
                    // keepAlive: true,
                    activePath: "/approval_manage/index"

                },
            },
            /**发行管理 */
            {
                path: 'publish_manage',
                name: 'publish_manage',
                component: () => import('@/pages/coupon_manage/publish_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '发行管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**使用管理 */
            {
                path: 'use_manage',
                name: 'use_manage',
                component: () => import('@/pages/coupon_manage/use_manage'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '使用管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/business_manage/use_detail',
                name: 'use_detail',
                component: () => import('@/pages/coupon_manage/use_detail'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '使用管理详情',
                    activePath: "/coupon_manage/use_manage"
                },

            },
            {
                path: '/business_manage/publish_detail',
                name: 'bus_publish_detail',
                component: () => import('@/pages/coupon_manage/publish_detail'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '发行管理详情',
                    activePath: "/coupon_manage/publish_manage"
                },
            },
            {
                path: 'offline-coupon_manage',
                name: 'offline-coupon_manage',
                component: () => import('@/pages/coupon_manage/offline-coupon_manage'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '线下券管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: 'use_detail',
                name: 'coupon_use_detail',
                component: () => import('@/pages/coupon_manage/use_detail'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '使用管理详情',
                }
            },
            {
                path: 'publish_detail',
                name: 'publish_detail',
                component: () => import('@/pages/coupon_manage/publish_detail'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '发行管理详情',
                }
            },
            {
                path: '/business_manage/add_publish',
                name: 'add_publish',
                component: () => import('@/pages/coupon_manage/add_publish'),
                hidden: true,
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    hidden: true,
                    title: '添加优惠券',
                    activePath: "/coupon_manage/publish_manage"
                }
            },
        ]
    },
    {
        path: '/offpeak_manage',
        name: '/offpeak_manage',
        component: () => import('@/pages/offpeak_manage'),
        children: [
            {
                path: '/offpeak_manage/parking_list',
                name: 'parking_list',
                component: () => import('@/pages/offpeak_manage/parking_list'),
                meta: {
                    title: '车场管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/offpeak_manage/add_parking',
                name: 'add_parking',
                component: () => import('@/pages/offpeak_manage/add_parking'),
                meta: {
                    title: '新增错峰车场',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    activePath: "/offpeak_manage/parking_list"
                }
            },
            {
                path: '/offpeak_manage/appoint_manage',
                name: 'appoint_manage',
                component: () => import('@/pages/offpeak_manage/appoint_manage'),
                meta: {
                    title: '预约管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/offpeak_manage/add_appoint',
                name: 'add_appoint',
                component: () => import('@/pages/offpeak_manage/add_appoint'),
                meta: {
                    title: '新增错峰预约',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    activePath: "/offpeak_manage/appoint_manage"
                }
            },
            {
                path: '/offpeak_manage/lease_order',
                name: 'off_lease_order',
                component: () => import('@/pages/offpeak_manage/lease_order'),
                meta: {
                    title: '长租订单',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/offpeak_manage/add_lease_order',
                name: 'add_lease_order',
                component: () => import('@/pages/offpeak_manage/add_lease_order'),
                meta: {
                    title: '新增错峰长租订单',
                    icon: "el-icon-bell",
                    keepAlive: true,
                    activePath: "/offpeak_manage/lease_order"
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '错峰管理',
            icon: "el-icon-bell"
        }
    },
    {
        path: '/coupon_manage',
        name: 'oper_coupon_manage',
        component: () => import('@/pages/operation'),
        children: [],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '优惠券管理',
            icon: "el-icon-bell"
        }
    },
    // 运营管理
    {
        path: '/operation_manage',
        name: 'operation_manage',
        component: () => import('@/pages/operation'),
        children: [
            /**动态菜单 */
            {
                path: '/operation_manage/blackwhitelist',
                name: 'blackwhitelist',
                component: () => import('@/pages/operation/blackwhitelist/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '黑白名单',
                    icon: "el-icon-bell",
                    keepAlive: false
                }
            },
            {
                path: '/operation_manage/add_blackwhitelist',
                name: 'add_blackwhitelist',
                component: () => import('@/pages/operation/blackwhitelist/add_list'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '新增黑白名单',
                    icon: "el-icon-bell",
                    activePath: "/operation_manage/blackwhitelist"
                }
            },
            {
                path: '/operation_manage/visit_list',
                name: 'visit_list',
                component: () => import('@/pages/operation/visit_list/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '访客预约',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/operation_manage/add_visit',
                name: 'add_visit',
                component: () => import('@/pages/operation/visit_list/add_visit'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '创建访客预约',
                    icon: "el-icon-bell",
                    hidden: true,
                    activePath: "/operation_manage/visit_list"
                },
                hidden: true,

            },
            /**动态菜单 */
            {
                path: '/operation_manage/manual_review',
                name: 'manual_review',
                component: () => import('@/pages/operation/manual_review/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '人工审核',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/operation_manage/manual_review/parkinglot_detail',
                name: 'parkinglot_detail',
                component: () => import('@/pages/operation/manual_review/parkinglot_detail'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '人工审核详情',
                    icon: "el-icon-bell",
                    hidden: true
                }
            },
            {
                path: '/operation_manage/manual_review/order_processing',
                name: 'order_processing',
                component: () => import('@/pages/operation/manual_review/order_processing'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '订单处理',
                    icon: "el-icon-bell",
                    hidden: true
                }
            },
            {
                path: '/operation_manage/vehicle_arrears',
                name: 'vehicle_arrears',
                component: () => import('@/pages/operation/vehicle_arrears/index'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '车辆欠费',
                    icon: "el-icon-bell"
                }
            }
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '运营管理',
            icon: "el-icon-bell"
        }
    },
    // 异常数据
    {
        path: '/exception_data',
        name: 'exception_data',
        component: () => import('@/pages/exception'),
        children: [
            /**动态菜单 */
            {
                path: '/exception_data/overtime_parking',
                name: 'overtime_parking',
                component: () => import('@/pages/exception/overtime_parking/index'),
                meta: {
                    title: '超时停车',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**动态菜单 */
            {
                path: '/exception_data/abnormal_data',
                name: 'abnormal_data',
                component: () => import('@/pages/exception/abnormal_data/index'),
                meta: {
                    title: '数据异常',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '异常数据',
            icon: "el-icon-bell"
        }
    },
    // 财务中心
    {
        path: '/finance_center',
        name: 'finance_center',
        component: () => import('@/pages/finance'),
        children: [
            /**资金管理 */
            {
                path: '/finance_center/capital_income',
                name: 'capital_in',
                component: () => import('@/pages/finance/capital_manage'),
                redirect: '/finance_center/capital_income/income_flow',
                children: [
                    {
                        path: '/financial_center/income_manage',
                        name: 'income_manage',
                        component: () => import('@/pages/financial_center/funds_manage/income_manage'),
                        // component: () => import('@/pages/finance/capital_manage/income_flow'),
                        meta: {
                            title: '收入流水',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    /**动态菜单 */
                    {
                        path: '/financial_center/recharge_manage',
                        name: 'recharge_manage',
                        component: () => import('@/pages/financial_center/funds_manage/recharge_manage'),
                        // component: () => import('@/pages/finance/capital_manage/recharge_search'),
                        meta: {
                            title: '充值查询',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    {
                        path: '/financial_center/payment_log',
                        name: 'payment_log',
                        component: () => import('@/pages/financial_center/funds_manage/payment_log'),
                        // component: () => import('@/pages/finance/capital_manage/pay_log'),
                        meta: {
                            title: '对账管理',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    {
                        path: '/financial_center/bad_debts',
                        name: 'bad_debts',
                        component: () => import('@/pages/financial_center/funds_manage/bad_debts'),
                        meta: {
                            title: '坏账管理',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                ],
                meta: {
                    title: '资金管理',
                    icon: "el-icon-bell"
                }
            },
            {
                path: '/finance_center/capital_income',
                name: 'capital_income',
                component: () => import('@/pages/finance/capital_manage'),
                redirect: '/finance_center/capital_income/income_flow',
                children: [
                    {
                        path: '/financial_center/sorting_manage',
                        name: 'sorting_manage',
                        component: () => import('@/pages/financial_center/settle_manage/sorting_manage'),
                        meta: {
                            title: '清分汇总',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                    {
                        path: '/financial_center/sorting_manage_detail',
                        name: 'sorting_manage_detail',
                        component: () => import('@/pages/financial_center/settle_manage/sorting_manage_detail'),
                        meta: {
                            title: '清分明细',
                            icon: "el-icon-bell",
                            activePath: "/financial_center/sorting_manage"
                            // keepAlive: true
                        }
                    },
                    {
                        path: '/financial_center/sorting_summary',
                        name: 'sorting_summary',
                        component: () => import('@/pages/financial_center/settle_manage/sorting_summary'),
                        meta: {
                            title: '清分管理',
                            icon: "el-icon-bell",
                            keepAlive: true
                        }
                    },
                ],
                meta: {
                    title: '结算管理',
                    icon: "el-icon-bell"
                },
            },
            {
                path: '/finance/onbills_search',
                name: 'onbills_search',
                component: () => import('@/pages/finance/capital_manage/onbills_search'),
                meta: {
                    requiresAuth: true,
                    isAdmin: true,
                    title: '对账单查询',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/financial_scnen',
                name: 'financial_scnen',
                component: () => import('@/pages/financial_center/financial_scnen/index'),
                meta: {
                    title: '财务审核',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/financial_scnen_detail',
                name: 'financial_scnen_detail',
                component: () => import('@/pages/financial_center/financial_scnen/financial_scnen_detail'),
                meta: {
                    title: '清分汇总明细',
                    icon: "el-icon-bell",
                    activePath: "/financial_center/sorting_manage"
                }
            },
            {
                path: '/financial_center/financial_invoice',
                name: 'financial_invoice',
                component: () => import('@/pages/financial_center/financial_invoice'),
                meta: {
                    title: '电子发票',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/withdrawal_audit',
                name: 'withdrawal_audit',
                component: () => import('@/pages/financial_center/withdrawal_audit/index'),
                meta: {
                    title: '提现审核',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/channel_setting',
                name: 'channel_setting',
                component: () => import('@/pages/financial_center/channel_setting/index'),
                meta: {
                    title: '结算通道配置',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/rules_setting',
                name: 'rules_setting',
                component: () => import('@/pages/financial_center/channel_setting/rules_setting'),
                meta: {
                    title: '提现规则配置',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            {
                path: '/financial_center/withdrawal_setting',
                name: 'withdrawal_setting',
                component: () => import('@/pages/financial_center/withdrawal_audit/withdrawal_setting'),
                meta: {
                    title: '财务配置',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**动态菜单 */

            /**动态菜单 */
            {
                path: '/finance_center/capital_income/trade_search',
                name: 'trade_search',
                component: () => import('@/pages/finance/capital_manage/trade_search'),
                meta: {
                    title: '交易查询',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },

            /**资金看板 */
            {
                path: '/finance_center/funds_bulletin',
                name: 'funds_bulletin',
                component: () => import('@/pages/finance/funds_bulletin'),
                meta: {
                    title: '资金看板',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**手续费设置 */
            {
                path: '/finance_center/set_commission',
                name: 'set_commission',
                component: () => import('@/pages/finance/set_commission'),
                meta: {
                    title: '手续费设置',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**提现管理 */
            {
                path: '/finance_center/cash_out',
                name: 'cash_out',
                component: () => import('@/pages/finance/cashout/index'),
                meta: {
                    title: '提现管理',
                    icon: 'el-icon-bell',
                    keepAlive: true
                }
            }
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '财务中心',
            icon: "el-icon-bell"
        }
    },
    // 系统管理 动态菜单
    {
        path: '/system_manage',
        name: 'system_manage',
        component: () => import('@/pages/system'),
        children: [
            /**运营人员 */
            {
                path: '/system_manage/operators',
                name: 'operators',
                component: () => import('@/pages/system/operators'),
                meta: {
                    title: '运营人员',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /** 角色管理 */
            {
                path: '/system_manage/role_manage',
                name: 'role_manage',
                component: () => import('@/pages/system/role_manage'),
                meta: {
                    title: '角色管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
            /**权限管理 */
            {
                path: '/system_manage/power_manage',
                name: 'power_manage',
                component: () => import('@/pages/system/power_manage'),
                meta: {
                    title: '权限管理',
                    icon: "el-icon-bell",
                    keepAlive: true
                }
            },
        ],
        meta: {
            requiresAuth: true,
            isAdmin: true,
            title: '系统管理',
            icon: "el-icon-bell"
        }
    },
]
export default routes;
