<template>
  <div class="data_board">
    <div class="head">
      <div class="head-inner">
        <span>比特泊车智慧停车数据中心</span>
      </div>
    </div>
    <div class="content">
      <div class="side">
        <div class="service-count count-card card">
          <div class="card-title">
            <img src="@/assets/images/data_board/service-count-icon.png"/>
            <span>服务车次</span>
          </div>
          <div class="card-content">
            <div class="count-value">{{ parkingCount }}</div>
          </div>
        </div>
        <div class="today-turnover-rate today-card card">
          <div class="card-title">
            <img src="@/assets/images/data_board/service-count-icon.png"/>
            <span>今日周转率</span>
          </div>
          <div class="card-content">
            <BaseChart
                ref="todayTurnoverRate"
                :option="todayTurnOverRateOption"
                style="height: 100%"
            />
          </div>
        </div>
        <div class="today-parking-analysis today-card card">
          <div class="card-title">
            <img src="@/assets/images/data_board/service-count-icon.png"/>
            <span>今日停车时长分析</span>
          </div>
          <div class="card-content" style="position: relative">
            <div class="parking-time-analysis">
              <BaseChart
                  ref="todayParkingTimeAnalysis"
                  :option="todayParkingTimeAnalysis"
                  style="height: 100%"
              />
            </div>
            <div class="parking-time-analysis-legend">
              <div
                  v-for="(item, index) in todayAnalysisLegend"
                  :key="index"
                  :class="item.class"
                  class="legend-item"
              >
                <div>{{ item.name }}</div>
                <div class="rate">{{ item.amount }}%</div>
              </div>
            </div>
          </div>
        </div>
        <div class="today-ps-usedrate today-card card">
          <div class="card-title">
            <img src="@/assets/images/data_board/service-count-icon.png"/>
            <span>今日车位使用率</span>
          </div>
          <div class="card-content">
            <BaseChart
                ref="todayUsedRateOption"
                :option="todayUsedRateOption"
                style="height: 100%"
            />
          </div>
        </div>
      </div>
      <div class="center-content">
        <div class="total-summary">
          <div class="summary-item">
            <img src="@/assets/images/data_board/total-parking-count.png"/>
            <div>
              <p>{{ totalParkingLotCount }}</p>
              <p class="label">车场总数</p>
            </div>
          </div>
          <div class="summary-item">
            <img src="@/assets/images/data_board/total-ps-count.png"/>
            <div>
              <p>{{ totalParkingSpaceCount }}</p>
              <p class="label">总车位数</p>
            </div>
          </div>
        </div>
        <div class="jimo_map">
          <BaseChart
              ref="jimo_map"
              :lazy="true"
              :map="mapOption.map"
              :option="mapOption.options"
              style="height: 100%"
          />
        </div>
        <div class="parking-type-legend">
          <div class="parking-legend op">运营车场</div>
          <div class="parking-legend net">联网车场</div>
          <div class="parking-legend share">共享车场</div>
        </div>
        <div class="parking-type-list">
          <div class="op-parking-list">
            <dis-scroll-table
                :data="opParkingList"
                :isLoop="true"
                td-cell-class="td"
                th-cell-class="th"
            >
              <scroll-table-column
                  align="left"
                  label="运营车场"
                  prop="parkingName"
              ></scroll-table-column>
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="剩余车位"
                  prop="freePsCount"
              />
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="总车位"
                  prop="totalPsCount"
              />
            </dis-scroll-table>
          </div>
          <div class="op-parking-list">
            <dis-scroll-table
                :data="netParkingList"
                :isLoop="true"
                td-cell-class="td"
                th-cell-class="th"
            >
              <scroll-table-column
                  align="left"
                  label="联网车场"
                  prop="parkingName"
              ></scroll-table-column>
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="剩余车位"
                  prop="freePsCount"
              />
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="总车位"
                  prop="totalPsCount"
              />
            </dis-scroll-table>
          </div>
          <div class="op-parking-list">
            <dis-scroll-table
                :data="shareParkingList"
                :isLoop="true"
                td-cell-class="td"
                th-cell-class="th"
            >
              <scroll-table-column
                  align="left"
                  label="共享车场"
                  prop="parkingName"
              ></scroll-table-column>
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="剩余车位"
                  prop="freePsCount"
              />
              <scroll-table-column
                  :width="80"
                  align="center"
                  label="总车位"
                  prop="totalPsCount"
              />
            </dis-scroll-table>
          </div>
        </div>
      </div>
      <div class="side">
        <div class="service-count count-card card">
          <div class="card-title">
            <img src="@/assets/images/data_board/user-count-icon.png"/>
            <span>用户数量</span>
          </div>
          <div class="card-content">
            <div class="count-value">{{ totalUserCount }}</div>
          </div>
        </div>
        <div class="trade-summary card">
          <div class="card-title">
            <img src="@/assets/images/data_board/user-count-icon.png"/>
            <span>交易总览</span>
          </div>
          <div class="card-content">
            <div class="trade-summary-inner">
              <img
                  src="@/assets/images/data_board/trade-summary-left-icon.png"
              />
              <div class="trade-item">
                <p class="label">今日交易额</p>
                <p class="value">{{ todayTradeAmount }}</p>
              </div>
              <div class="trade-item">
                <p class="label">本月交易额</p>
                <p class="value">{{ monthTradeAmount }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-method">
          <div class="payment-method-chart">
            <BaseChart
                ref="paymentMethodOption"
                :option="paymentMethodOption"
                style="height: 100%"
            />
            <div class="inner-circle">占比</div>
          </div>
        </div>
        <div class="payment-type">
          <div class="payment-type-chart">
            <BaseChart
                ref="paymentTypeOption"
                :option="paymentTypeOption"
                style="height: 100%"
            />
            <div class="inner-circle">占比</div>
          </div>
        </div>
        <div class="realtime-trade">
          <dis-scroll-table
              :data="realTimeDataList"
              :isLoop="true"
              td-cell-class="td"
              th-cell-class="th"
          >
            <scroll-table-column
                align="left"
                label="共享车场"
                prop="parkingName"
            ></scroll-table-column>
            <scroll-table-column
                align="center"
                label="车牌号码"
                prop="plateNumber"
            ></scroll-table-column>
            <scroll-table-column
                align="center"
                label="交易时间"
                prop="tradeTime"
            />
            <scroll-table-column
                :width="40"
                align="center"
                label="交易金额"
                prop="tradeAmount"
            />
            <scroll-table-column align="center" label="渠道" prop="tradeType"/>
            <div
                slot="head"
                style="
                height: 100%;
                display: flex;
                color: #fff;
                align-items: center;
                justify-content: center;
              "
            >
              <span>实时交易</span>
            </div>
          </dis-scroll-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Pie from "@/easy/chart/pie";
import BaseChart from "@/components/BaseChart";
import mapJson from "@/assets/geo/map.json";
import {Column, Table} from "@/components/scroll-table";
import {
  getParkingList,
  getParkingPaymentCompare,
  getParkingTodayTurnOverRate,
  getParkingTotalCount,
  getPaymentChannelRatio,
  getPsUsedDetailByParkingType,
  getRealTimeTradeList,
  getToayPsUseRate,
  getTodayParkingAnalysis,
  getTotalParkingTrade,
} from "@/api/dataBoard";

export default {
  name: "data_board",
  components: {
    BaseChart,
    [Table.name]: Table,
    [Column.name]: Column,
  },
  computed: {
    todayAnalysisLegend() {
      const map = {
        m0_15: {
          name: "<15M",
          class: "_1h_4h",
        },
        m15_30: {
          name: "15M-30M",
          class: "_1h_4h",
        },
        m30_60: {
          name: "30M-1H",
          class: "_4h_7h",
        },
        h1_2: {
          name: "1H-2H",
          class: "_7h_10h",
        },
        h2_3: {
          name: "2H-3H",
          class: "_10h_12h",
        },
        h3_10: {
          name: "3H-10H",
          class: "more_12h",
        },
      };

      if (this.todayParkingAnalysis.length === 0) {
        return [];
      }

      const temp = this.todayParkingAnalysis.map((item) => {
        const o = map[item.parkingTimeType];
        o["amount"] = item.amount;
        return o;
      });
      return temp;
    },
  },
  watch: {
    todayAnalysisLegend(newVal) {
      if (newVal) {
        this.todayParkingTimeAnalysis.series[0].data = newVal.map((item) => {
          return {
            name: item.name,
            value: item.amount,
          };
        });
        this.$refs.todayParkingTimeAnalysis.render(
            this.todayParkingTimeAnalysis
        );
      }
    },
  },
  data() {
    return {
      // 服务车次
      parkingCount: 40,
      // 总车场数
      totalParkingLotCount: 40,
      // 总泊位数
      totalParkingSpaceCount: 40,
      // 用户数量
      totalUserCount: 40,

      opParkingList: [],
      netParkingList: [],
      shareParkingList: [],

      realTimeDataList: [],
      // 今日周转率
      todayTurnOverRateOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: "#FFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#F2F2F2",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#FFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#F2F2F2",
            },
          },
        },
        grid: {
          // show: true,
          containLabel: true,
          left: 30,
          right: 40,
          bottom: 20,
          top: 50,
        },
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              color: "#3CB7E6",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.1,
                    color: "#0E9CFF", // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#000", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      },
      // 今日使用率
      todayUsedRateOption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: "#FFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#F2F2F2",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#FFF",
          },
          axisLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              color: "#F2F2F2",
            },
          },
        },
        grid: {
          // show: true,
          containLabel: true,
          left: 30,
          right: 40,
          bottom: 20,
          top: 50,
        },
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              color: "#3CB7E6",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0.1,
                    color: "#0E9CFF", // 0% 处的颜色
                  },
                  {
                    offset: 0.8,
                    color: "#000", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      },
      // 今日交易额
      todayTradeAmount: 0,
      // 本月交易额
      monthTradeAmount: 0,
      todayParkingAnalysis: [],
      mapOption: {
        map: {
          name: "jimo",
          svg: "",
          type: "svg",
        },
        options: {
          geo: [
            {
              map: "jimo",
            },
          ],
          series: [],
        },
      },
      // 线上线下占比
      paymentMethodOption: {
        tooltip: {
          trigger: "item",
        },
        color: ["#39FDC0", "#40CDF1"],
        series: [
          {
            name: "线上线下占比",
            type: "pie",
            radius: ["50%", "70%"],
            emphasis: {
              disabled: false,
            },
            select: {
              disabled: false,
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}占比: {d}%",
              color: "#FFF",
            },
            labelLine: {
              length: 10, // 修改引导线第一段的长度
              length2: 25, // 修改引导线第二段的长度
              lineStyle: {
                color: "#FFF", // 修改引导线的颜色
              },
            },
            data: [
              // { value: 7, name: "线上" },
              // { value: 3, name: "线下" },
            ],
          },
        ],
      },
      paymentTypeOption: {
        tooltip: {
          trigger: "item",
        },
        color: ["#39FDC0", "#40CDF1"],
        series: [
          {
            name: "支付方式占比",
            type: "pie",
            radius: ["60%", "80%"],
            emphasis: {
              // label: {
              //   show: true,
              //   fontSize: "40",
              //   fontWeight: "bold",
              // },
              disabled: false,
            },
            select: {
              disabled: false,
            },
            label: {
              show: true,
              position: "outside",
              formatter: "{b}占比: {d}%",
              color: "#FFF",
            },
            labelLine: {
              length: 20, // 修改引导线第一段的长度
              length2: 25, // 修改引导线第二段的长度
              lineStyle: {
                color: "#FFF", // 修改引导线的颜色
              },
            },
            data: [
              {value: 3, name: "微信"},
              {value: 4, name: "支付宝"},
              {value: 3, name: "ETC"},
              {value: 1, name: "银联"},
              {value: 2, name: "其他"},
            ],
          },
        ],
      },
      // 今日停车时长分析
      todayParkingTimeAnalysis: {
        tooltip: {
          trigger: "item",
        },
        color: [
          "#517EFC",
          "#40CDF1",
          "#39FDC0",
          "#FF9E34",
          "#F7E48B",
          "#EB595A",
        ],
        series: [
          {
            name: "今日停车时长分析",
            type: "pie",
            radius: ["30%", "90%"],
            emphasis: {
              disabled: false,
            },
            select: {
              disabled: false,
            },
            label: {
              show: false,
            },
            roseType: "area",
            right: "60%",
            data: [
              // { value: 40, name: "<1H" },
              // { value: 28, name: "1H-4H" },
              // { value: 24, name: "4H-7H" },
              // { value: 35, name: "7H-10H" },
              // { value: 8, name: "10H-12H" },
              // { value: 15, name: ">12H" },
            ],
          },
        ],
      },
    };
  },
  methods: {
    formatGeoJSON(geoJSON) {
      const features = geoJSON["features"];
      features.forEach((item) => {
        if (item["geometry"]["type"] === "GeometryCollection") {
          let geometry = item["geometry"];
          let geometries = geometry["geometries"];

          geometry["type"] = "Polygon";
          geometry["coordinates"] = geometries.map((geo) => {
            return geo["coordinates"][0];
          });
        }
      });
      console.log((geoJSON, '这是啥!!!!'))
      return geoJSON;

    },
    async getParkingTotalCount() {
      const {returnObject} = await getParkingTotalCount();
      const {
        parkingCount,
        totalParkingLotCount,
        totalParkingSpaceCount,
        totalUserCount,
      } = returnObject;
      this.parkingCount = parkingCount;
      this.totalParkingLotCount = totalParkingLotCount;
      this.totalParkingSpaceCount = totalParkingSpaceCount;
      this.totalUserCount = totalUserCount;
    },
    async getParkingTodayTurnOverRate() {
      const {returnObject} = await getParkingTodayTurnOverRate();
      this.todayTurnOverRateOption.xAxis.data = returnObject.map(
          (item) => item.hour
      );
      this.todayTurnOverRateOption.series[0].data = returnObject.map(
          (item) => item.turnoverRate
      );
      this.$refs.todayTurnoverRate.render(this.todayTurnOverRateOption);
    },
    async getTodayParkingAnalysis() {
      const {returnObject} = await getTodayParkingAnalysis();
      this.todayParkingAnalysis = returnObject.list.slice(0, 6);
    },
    async getToayPsUseRate() {
      const {returnObject} = await getToayPsUseRate();

      this.todayUsedRateOption.xAxis.data = returnObject.map(
          (item) => item.hour
      );
      this.todayUsedRateOption.series[0].data = returnObject.map(
          (item) => item.utilizationRate
      );
      this.$refs.todayUsedRateOption.render(this.todayUsedRateOption);
    },
    // 交易总览
    async getTotalParkingTrade() {
      const {returnObject} = await getTotalParkingTrade();
      this.todayTradeAmount = returnObject.todayTradeAmount;
      this.monthTradeAmount = returnObject.monthTradeAmount;
    },
    async getParkingPaymentCompare() {
      const {returnObject} = await getParkingPaymentCompare();
      this.paymentMethodOption.series[0].data = returnObject.map((item) => {
        return {
          name: item.typeName,
          value: item.typeRate,
        };
      });
      this.$refs.paymentMethodOption.render(this.paymentMethodOption);
    },
    async getPaymentChannelRatio() {
      const {returnObject} = await getPaymentChannelRatio();
      this.paymentTypeOption.series[0].data = returnObject.map((item) => {
        return {
          name: item.channelName,
          value: item.channelRate,
        };
      });
      this.$refs.paymentTypeOption.render(this.paymentTypeOption);
    },

    async getRealTimeTradeList() {
      const {returnObject} = await getRealTimeTradeList();
      this.realTimeDataList = returnObject.map((item) => {
        item.tradeType = item.tradeType == "1" ? "支付宝" : "微信";
        return item;
      });
    },

    async getPsUsedDetailByParkingType(parkingType) {
      const {returnObject} = await getPsUsedDetailByParkingType(parkingType);
      return returnObject;
    },

    async getPsUsedByParkingType() {
      this.opParkingList = await this.getPsUsedDetailByParkingType(0);
      this.netParkingList = await this.getPsUsedDetailByParkingType(1);
      this.shareParkingList = await this.getPsUsedDetailByParkingType(2);
    },

    async getParkingList() {
      const {returnObject} = await getParkingList();
      const opParkingList = returnObject.filter(
          (item) => item.parkingType === "0"
      );
      const shareParkingList = returnObject.filter(
          (item) => item.parkingType === "1"
      );
      const netParkingList = returnObject.filter(
          (item) => item.parkingType === "2"
      );

      this.mapOption.options.series.push({
        type: "effectScatter",
        coordinateSystem: "geo",
        symbolSize: function (val) {
          return val[2] / 10;
        },
        itemStyle: {
          color: "#25d1ff",
        },
        data: opParkingList.map((item) => {
          return [item.longitude, item.latitude, 100];
        }),
      });
      this.mapOption.options.series.push({
        type: "effectScatter",
        coordinateSystem: "geo",
        symbolSize: function (val) {
          return val[2] / 10;
        },
        itemStyle: {
          color: "#ff0066",
        },
        data: shareParkingList.map((item) => {
          return [item.longitude, item.latitude, 100];
        }),
      });
      this.mapOption.options.series.push({
        type: "effectScatter",
        coordinateSystem: "geo",
        symbolSize: function (val) {
          return val[2] / 10;
        },
        itemStyle: {
          color: "#f7e48b",
        },
        data: netParkingList.map((item) => {
          return [item.longitude, item.latitude, 100];
        }),
      });

      this.$refs["jimo_map"].render(this.mapOption.options);

      console.log(opParkingList);
      console.log(shareParkingList);
      console.log(netParkingList);
    },
  },
  mounted() {
    this.$refs["jimo_map"].registMap({
      name: "jimo",
      geoJSON: this.formatGeoJSON(mapJson),
      type: "geoJSON",
    });
    this.$refs["jimo_map"].init().render({
      geo: [
        {
          map: "jimo",
          zoom: 1,
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: false,
            },
            itemStyle: {
              areaColor: "#FF0066",
            },
          },
          itemStyle: {
            areaColor: "#133551",
            borderColor: "#18E1FF",
            borderWidth: 2,
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowBlur: 10,
          },
        },
      ],
    });

    // 获取数据大屏服务车次、车场总数、总车位数以及用户数量
    this.getParkingTotalCount();

    // 获取当日12小时周转率
    this.getParkingTodayTurnOverRate();

    // 今日停车时长分析
    this.getTodayParkingAnalysis();

    // 今日车位使用率
    this.getToayPsUseRate();

    // 今日交易总览
    this.getTotalParkingTrade();

    // 线上线下占比
    this.getParkingPaymentCompare();

    this.getPaymentChannelRatio();

    this.getRealTimeTradeList();

    this.getPsUsedByParkingType();

    this.getParkingList();
  },
};
</script>

<style lang="less" scoped>
@import url("@/styles/convert.less");

@headHeight: .vh(77px) [ @val];
@sideWidth: .vw(448px) [ @val];

.data_board {
  width: 100%;
  height: 100%;

  background: url("../assets/images/data_board/data_board_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .head {
    height: @headHeight;

    .head-inner {
      .vh(60px, height);
      .vh(17px, margin-top);
      background: url("../assets/images/data_board/head.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      text-align: center;

      span {
        .vw(40px, font-size);
        font-family: FZLanTingHei-DB-GBK-Regular, FZLanTingHei-DB-GBK;
        font-weight: 400;
        .vh(47px, line-height);
        background: linear-gradient(180deg, #96fff6 0%, #4bccff 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .content {
    height: calc(100% - @headHeight);
    padding: 0 40px;
    overflow: hidden;

    display: flex;
    flex-direction: row;

    .side {
      width: @sideWidth;

      .count-card {
        .vh(167px);
        background-image: url(../assets/images/data_board/service-count-bg.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        .count-value {
          .vw(70px, font-size);
          font-weight: 500;
          .vw(140px, line-height);
          letter-spacing: 7px;
          text-shadow: 0 0 13px rgba(75, 126, 254, 0.5);
          background: linear-gradient(0deg, #25d1ff 0%, #8affff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-align: center;
        }
      }

      .card {
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .card-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          .vw(20px, margin-left);
          .vh(10px, margin-top);
          color: #fff;
          .vw(18px, font-size);

          img {
            .vw(20px, max-width);
          }
        }

        .card-content {
          flex: 1;
        }
      }

      .today-card {
        .vh(249px);
        background-image: url(../assets/images/data_board/today-turnover-bg.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .vh(20px, margin-top);
      }

      .trade-summary {
        .vh(149px);
        background-image: url(../assets/images/data_board/trade-summary.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .vh(20px, margin-top);

        .trade-summary-inner {
          display: flex;
          flex-direction: row;
          align-items: center;
          .vw(30px, margin-left);
          .vh(15px, margin-top);

          img {
            .vw(100px, width);
            .vh(100px, height);
          }

          .trade-item {
            color: #fff;
            .vw(16px, font-size);
            .vw(30px, margin-left);

            .value {
              .vw(30px, font-size);
              font-family: Anton-Regular, Anton;
              font-weight: 400;
              .vh(35px, line-height);
              letter-spacing: 3px;
              text-shadow: 0 0 13px rgba(75, 126, 254, 0.5);
              background: linear-gradient(0deg, #25d1ff 0%, #8affff 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }

      .payment-method {
        .vh(147px);
        background-image: url(../assets/images/data_board/payment-method.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .vh(20px, margin-top);
        display: flex;
        align-items: center;
        justify-content: center;

        .payment-method-chart {
          width: 100%;
          .vw(106, height);

          .vh(14px, margin-top);

          background: url(../assets/images/data_board/wrap-circle.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          position: relative;

          .inner-circle {
            .vw(40px, width);
            .vh(40px, height);
            // height: 40px;
            background: linear-gradient(179deg,
            rgba(64, 205, 241, 0) 0%,
            rgba(64, 205, 241, 0.02) 63%,
            rgba(42, 182, 218, 0.5) 88%);
            box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            opacity: 1;
            border: 1px solid #ddd;
            border-radius: 100px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
      }

      .payment-type {
        .vh(196px);
        background-image: url(../assets/images/data_board/payment-type.png);
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        .vh(20px, margin-top);
        display: flex;
        align-items: center;
        justify-content: center;

        .payment-type-chart {
          width: 100%;
          .vw(106, height);

          .vh(14px, margin-top);

          background: url(../assets/images/data_board/wrap-circle.png);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          position: relative;

          .inner-circle {
            .vw(50px, width);
            .vh(50px, height);
            // height: 40px;
            background: linear-gradient(179deg,
            rgba(64, 205, 241, 0) 0%,
            rgba(64, 205, 241, 0.02) 63%,
            rgba(42, 182, 218, 0.5) 88%);
            box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            opacity: 1;
            border: 1px solid #ddd;
            border-radius: 100px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
      }

      .realtime-trade {
        .vh(240px);
        .vh(14px, margin-top);
      }

      .parking-time-analysis {
        .vh(170px, height);
        .vh(40px, margin-top);
      }

      .parking-time-analysis-legend {
        position: absolute;
        .vw(190px);
        .vh(150px);
        .vw(40px, right);
        .vh(40px, top);
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .legend-item {
          .vw(95px);
          overflow: hidden;
          color: #fff;
          position: relative;
          margin-top: 10px;

          &.less_1h {
            color: #517efc;

            &::before {
              background: #517efc;
            }

            &::after {
              background: #517efc;
            }

            .rate {
              &::before {
                background: #517efc;
              }
            }
          }

          &._1h_4h {
            color: #40cdf1;

            &::before {
              background: #40cdf1;
            }

            &::after {
              background: #40cdf1;
            }

            .rate {
              &::before {
                background: #40cdf1;
              }
            }
          }

          &._4h_7h {
            color: #39fdc0;

            &::before {
              background: #39fdc0;
            }

            &::after {
              background: #39fdc0;
            }

            .rate {
              &::before {
                background: #39fdc0;
              }
            }
          }

          &._7h_10h {
            color: #ff9e34;

            &::before {
              background: #ff9e34;
            }

            &::after {
              background: #ff9e34;
            }

            .rate {
              &::before {
                background: #ff9e34;
              }
            }
          }

          &._10h_12h {
            color: #f7e48b;

            &::before {
              background: #f7e48b;
            }

            &::after {
              background: #f7e48b;
            }

            .rate {
              &::before {
                background: #f7e48b;
              }
            }
          }

          &.more_12h {
            color: #eb595a;

            &::before {
              background: #eb595a;
            }

            &::after {
              background: #eb595a;
            }

            .rate {
              &::before {
                background: #eb595a;
              }
            }
          }

          div {
            margin-left: 18px;
            margin-top: 5px;
          }

          &::before {
            position: absolute;
            content: "";
            width: 14px;
            height: 14px;
            background: red;
            top: 4px;
          }

          &::after {
            position: absolute;
            content: "";
            width: 1px;
            height: 12px;
            background: red;
            top: 18px;
            left: 6px;
          }

          .rate {
            position: relative;

            &::before {
              position: absolute;
              content: "";
              width: 10px;
              height: 1px;
              background: red;
              left: -12px;
              top: 6px;
            }
          }
        }
      }
    }

    .center-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .jimo_map {
        .vh(500px);
      }

      .parking-type-legend {
        .vh(60px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .parking-legend {
          .vw(114px);
          .vh(31px);
          text-align: center;
          .vh(31px, line-height);
          .vw(16px, font-size);
          position: relative;
          .vw(60px, margin-right);

          &.op {
            color: #25d1ff;
            background: linear-gradient(90deg,
            rgba(37, 209, 255, 0) 0%,
            rgba(37, 209, 255, 0.29) 49%,
            rgba(37, 209, 255, 0) 98%);
            border-radius: 0 0 0 0;
            opacity: 1;
            border: 1px solid;
            border-image: radial-gradient(circle,
            rgba(37, 209, 255, 1),
            rgba(37, 209, 255, 0)) 1 1;

            &::before {
              position: absolute;
              content: "";
              width: 18px;
              height: 18px;
              color: #25d1ff;
              box-shadow: 0 0 13px 1px #25d1ff;
              opacity: 1;
              border: 1px solid #25d1ff;
              border-radius: 100px;
              left: -40px;
              top: 6px;
            }

            &::after {
              position: absolute;
              content: "";
              width: 14px;
              height: 14px;
              background: #25d1ff;
              border-radius: 20px;
              left: -37px;
              top: 9px;
            }
          }

          &.net {
            color: #ff0066;
            background: linear-gradient(90deg,
            rgba(255, 0, 102, 0) 0%,
            rgba(255, 0, 102, 0.26) 49%,
            rgba(255, 0, 102, 0) 98%);
            border-radius: 0 0 0 0;
            opacity: 1;
            border: 1px solid;
            border-image: radial-gradient(circle,
            rgba(255, 0, 102, 1),
            rgba(255, 0, 102, 0)) 1 1;

            &::before {
              position: absolute;
              content: "";
              width: 18px;
              height: 18px;
              box-shadow: 0 0 13px 1px #ff0066;
              opacity: 1;
              border: 1px solid #ff0066;
              border-radius: 100px;
              left: -40px;
              top: 6px;
            }

            &::after {
              position: absolute;
              content: "";
              width: 14px;
              height: 14px;
              background: #ff0066;
              border-radius: 20px;
              left: -37px;
              top: 9px;
            }
          }

          &.share {
            color: #f7e48b;
            background: linear-gradient(90deg,
            rgba(247, 228, 139, 0) 0%,
            rgba(247, 228, 139, 0.29) 49%,
            rgba(247, 228, 139, 0) 97%);
            border-radius: 0 0 0 0;
            opacity: 1;
            border: 1px solid;
            border-image: radial-gradient(circle,
            rgba(247, 228, 139, 1),
            rgba(247, 228, 139, 0)) 1 1;

            &::before {
              position: absolute;
              content: "";
              width: 18px;
              height: 18px;
              box-shadow: 0 0 13px 1px #f7e48b;
              opacity: 1;
              border: 1px solid #f7e48b;
              border-radius: 100px;
              left: -40px;
              top: 6px;
            }

            &::after {
              position: absolute;
              content: "";
              width: 14px;
              height: 14px;
              background: #f7e48b;
              border-radius: 20px;
              left: -37px;
              top: 9px;
            }
          }
        }
      }

      .parking-type-list {
        display: flex;
        flex-direction: row;
        flex: 1;
        min-height: 100px;

        .vh(30px, margin-top);
        .vh(30px, margin-bottom);
        .vw(30px, margin-left);
        .vw(30px, margin-right);

        .op-parking-list {
          flex: 1;
          height: 100%;
          color: #fff;
          // display: none;
        }
      }

      .total-summary {
        display: flex;
        flex-direction: row;
        .vh(20px, margin-top);
        .vw(50px, font-size);

        .summary-item {
          display: flex;
          flex-direction: row;
          color: #fff;
          flex: 1;

          align-items: center;
          justify-content: center;

          img {
            .vw(120px);
            .vw(120px, height);
          }

          .label {
            font-size: 24px;
            font-family: FZLanTingHei-M-GBK-Regular, FZLanTingHei-M-GBK;
            font-weight: 400;
            color: #25d1ff;
            line-height: 28px;
            -webkit-background-clip: text;
            -webkit-text-fill-color: #25d1ff;
          }
        }
      }
    }
  }
}
</style>
