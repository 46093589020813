const state = {
    tab: 0
};

const mutations = {
    // 页面模块刷新
    CHANGE_TAB: (state, tab) => {
        state.tab = tab
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};