const state = {
    routeMenu: [],
    cacheRoutes: [],
    lastClickTimeStamps:0,
    // isFirst:true,
};

const mutations = {
    addRouteMenu(state, route) {
        const whether = (item) => {
            return item.name === route.name;
        };
        if (state.routeMenu.findIndex(whether) < 0) {
            state.routeMenu.push(route);
        }
    },
    clearRouteMenu(state) {
        state.routeMenu = [];
    },
    removeAllRouteMenu(state) {
        // state.routeMenu = state.routeMenu.filter(r => r.name === 'home');
        state.routeMenu = state.routeMenu.slice(0, 1);
    },
    removeRoute(state, route) {
        const whether = (item) => {
            return item.name === route.name;
        };
        const index = state.routeMenu.findIndex(whether);
        state.routeMenu.splice(index, 1);
    },
    addCacheRoute(state, item) {
        state.cacheRoutes.push(item);
    },
    editlastClickTimeStamps(state, data){
        state.lastClickTimeStamps = data;
    },
    // editIsFirst(state,data){
    //     state.isFirst2 = data;
    //     setTimeout(()=>{
    //         state.isFirst2 = 1;
    //     },5000)
    // }
};

export default {
    namespaced: true,
    state,
    mutations
};
