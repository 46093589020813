import request from '@/utils/request';
import {objectToQueryString} from '@/utils/utils';


// 获取数据大屏服务车次、车场总数、总车位数以及用户数量
export function getParkingTotalCount() {
    return request.get('/dataLarge/getParkingTotalCount');
}

// m0_15 m15_30 m30_60 h1_2 h2_3 h3_10 h10_24 h24
// 获取当日12小时周转率
export function getParkingTodayTurnOverRate() {
    return request.get('/dataLarge/getParkingTodayTurnOverRate')
}

// 获取今日停车时长分析
export function getTodayParkingAnalysis() {
    return request.get('/dataLarge/getTodayParkingAnalysis');
}

// 获取当日车位使用率
export function getToayPsUseRate() {
    return request.get('/dataLarge/getToayPsUseRate');
}

// 获取车场列表
export function getParkingList() {
    return request.get('/dataLarge/getParkingList');
}

// 根据车场类型获取车场泊位占用详情
export function getPsUsedDetailByParkingType(parkingType) {
    return request.get('/dataLarge/getPsUsedDetailByParkingType?type=' + parkingType);
}

// 获取所有车场交易总览
export function getTotalParkingTrade() {
    return request.get('/dataLarge/getTotalParkingTrade');
}

// 获取车场缴费方式线上线下对比
export function getParkingPaymentCompare() {
    return request.get('/dataLarge/getParkingPaymentCompare');
}

// 获取缴费渠道占比
export function getPaymentChannelRatio() {
    return request.get('/dataLarge/getPaymentChannelRatio');
}

// 获取实时交易列表
export function getRealTimeTradeList() {
    return request.get('/dataLarge/getRealTimeTradeList');
}

/***********************************以下为路内看板接口*************************************/

// 获取车场概况信息
export function getParkingOverview(params) {
    return request.post('/parkingLotInfo/earningList?' + objectToQueryString(params));
}

// 今日收入趋势
export function searchParkingFeeChangeAnalyze(params) {
    return request.post('/parkingLotInfo/searchParkingFeeChangeAnalyze?' + objectToQueryString(params));
}

// 进出场流量分析
export function searchParkingOrderAnalyze(params) {
    return request.post('/parkingLotInfo/searchParkingOrderAnalyze?' + objectToQueryString(params));
}

// 进出场流量分析
export function searchParkingSpaceAnalyze(params) {
    return request.post('/parkingLotInfo/searchParkingSpaceAnalyze?' + objectToQueryString(params));
}
//
// 巡检分析
export function inspectorAnalisys(params) {
    return request.post('/parkingLotInfo/inspectorAnalisys?' + objectToQueryString(params));
}


// 综合分析
export function searchParkingLotAnalyze(params) {
    return request.post('/parkingLotInfo/searchParkingLotAnalyze?' + objectToQueryString(params));
}

// 泊位分析
export function searchParkingSpaceTotal(params) {
    return request.post('/parkingLotInfo/searchParkingSpaceTotal?' + objectToQueryString(params));
}

// 停车时长分析
export function searchParkingTimeAnylize(params) {
    return request.post('/parkingLotInfo/searchParkingTimeAnylize?' + objectToQueryString(params));
}

// 停车时长分析-新版
export function searchParkingTimeAnylizeNew(params) {
    return request.post('/parkingLotInfo/searchParkingTimeAnylizeNew?' + objectToQueryString(params));
}

// 任务趋势分析
export function searchParkingTaskAnalyze(params) {
    return request.post('/parkingLotInfo/searchParkingTaskAnalyze?' + objectToQueryString(params));
}

// 设备类型分析
export function parkingAndDeviceAnalisys(params) {
    return request.post('/parkingLotInfo/parkingAndDeviceAnalisys?' + objectToQueryString(params));
}
// 车场收入排名
export function parkingLotRevenueSort(params) {
    return request.post('/parkingLotInfo/parkingLotRevenueSort?' + objectToQueryString(params));
}

// 获取车辆业务分析
export function getAutoBusinessAnalysis(params) {
    return request.get('/bulletin/getParkingServiceAnalysis?' + objectToQueryString(params));
}

// 获取停留时长分析
export function getStopDurationAnalysis(params) {
    return request.get('/bulletin/getStopDurationAnalysis?' + objectToQueryString(params));
}

// 获取巡检人员分析
export function getInspectorsAnalysis(params) {
    return request.get('/bulletin/getInspectorsAnalysis?' + objectToQueryString(params));
}

// 获取停泊率趋势
export function getAnchorRatioTread(params) {
    return request.get('/bulletin/getAnchorRatioTread?' + objectToQueryString(params));
}

// 获取支付来源分析
export function getPaySourceAnalysis(params) {
    return request.get('/bulletin/getPaySourceAnalysis?' + objectToQueryString(params));
}


// 获取利用率趋势
export function getUtilizationTread(params) {
    return request.get('/bulletin/getUtilizationTread?' + objectToQueryString(params));
}

// 获取周转率趋势
export function getTurnoverTread(params) {
    return request.get('/bulletin/getTurnoverTread?' + objectToQueryString(params));
}


/***********************************以下为路外看板接口*************************************/

// 获取路外车场概况
export function getParkingRecevied(params) {
    return request.get('/bulletin/getParkingRecevied?' + objectToQueryString(params));
}

// 车场资源数据
export function getParkingResource(params) {
    return request.get('/bulletin/getParkingResource?' + objectToQueryString(params));
}

// 出入口流量分析
export function getPassageFlowAnalysis(params) {
    return request.get('/bulletin/getPassageFlowAnalysis?' + objectToQueryString(params));
}

// 异常订单分析
export function getExceptionOrderAnalysis(params) {
    return request.get('/bulletin/getExceptionOrderAnalysis?' + objectToQueryString(params));
}
